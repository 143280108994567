import { fadeOutAudio } from "../../../../../utils";

export default class AudioManager {
  constructor(options) {
    this.manager = new Map();
    this.isMute = false;
    this.captionsEnabled = options.captionsEnabled;
  }

  add(id, url, text) {
    const audioPlayer = new Audio();

    audioPlayer.src = url;
    audioPlayer.text = text;

    audioPlayer.addEventListener('loadedmetadata', () => {
      try {
        const duration = audioPlayer.duration;
        
        if (!audioPlayer.text || !duration) {
          return;
        }

        const words = audioPlayer.text.split(' ').filter(word => word.length > 0);
        const wordsPerChunk = Math.ceil(words.length / (duration / 2));
        
        if (wordsPerChunk <= 0) {
          return;
        }

        const chunks = [];
        for (let i = 0; i < words.length; i += wordsPerChunk) {
          chunks.push({
            text: words.slice(i, i + wordsPerChunk).join(' '),
            start: (i / wordsPerChunk) * 2,
            end: Math.min(((i / wordsPerChunk) + 1) * 2, duration)
          });
        }
        
        audioPlayer.captions = chunks;
      } catch (error) {
        console.warn('Error processing audio captions:', error);
        audioPlayer.captions = [];
      }
    });

    this.manager.set(id, audioPlayer);
  }

  getDuration() {
    return this.audioPlayer ? this.audioPlayer.duration : 0;
  }

  play(id, { onEnd = null, onStart = null } = {}) {
    const audioPlayer = this.manager.get(id);

    if (audioPlayer) {
      if(this.audioPlayer) {
        this.audioPlayer.pause();
        this.removeCaptionElement();
      }

      this.audioPlayer = audioPlayer;
      this.setAudioMute();
      this.setupCaptions(audioPlayer);

      audioPlayer.currentTime = 0;
      audioPlayer.volume = 1;

      const audioPromise = audioPlayer.play();

      if (audioPromise != undefined) {
        audioPromise
          .then(_ => {
            onStart?.();
          })
          .catch(err => {
            onEnd?.();
          });
      }

      if (onEnd) {
        this.audioPlayer.onended = () => {
          this.removeCaptionElement();
          onEnd();
        };
      }
    } else {
      this.audioPlayer = null;
    }

    return this.audioPlayer;
  }

  setupCaptions(audioPlayer) {
    try {
      const captionElement = document.createElement('div');

      captionElement.classList.add('storyscale-caption-container');

      captionElement.style.display = this.captionsEnabled ? 'block' : 'none';

      document.body.appendChild(captionElement);
      
      this.captionElement = captionElement;

      audioPlayer.ontimeupdate = () => {
        try {
          const currentTime = audioPlayer?.currentTime || 0;
          const currentCaption = audioPlayer?.captions?.find(
            caption => currentTime >= caption.start && currentTime <= caption.end
          );
          if (captionElement) {
            captionElement.textContent = currentCaption?.text || '';
          }
        } catch (err) {
          console.warn('Error updating captions:', err);
        }
      };
    } catch (err) {
      console.warn('Error setting up captions:', err);
      this.captionElement = null;
    }
  }

  enableCaptions() {
    this.captionsEnabled = true;
    if (this.captionElement) {
      this.captionElement.style.display = 'block';
    }
  }

  disableCaptions() {
    this.captionsEnabled = false;
    if (this.captionElement) {
      this.captionElement.style.display = 'none';
    }
  }

  removeCaptionElement() {
    if (this.captionElement) {
      this.captionElement.remove();
      this.captionElement = null;
    }
  }

  resume() {
    if (this.audioPlayer) {
      this.audioPlayer.play();
    }
  }

  pause() {
    if (this.audioPlayer) {
      fadeOutAudio(this.audioPlayer, 400);
      this.removeCaptionElement();
    }
  }

  error() {
    if (this.audioPlayer) {
    }
  }

  mute() {
    this.isMute = true;
    this.setAudioMute();
  }

  unMute() {
    this.isMute = false;
    this.setAudioMute();
  }

  setAudioMute() {
    if (this.audioPlayer) {
      this.audioPlayer.muted = this.isMute;
    }
  }
}
