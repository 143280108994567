<template>
    <div ref="tourHolder">
        <Loading :loading="loading"/>

        <WelcomeScreen v-if="shouldShowWelcomeScreen" @onStart="startTourManually" :theme="tour.audio_screen_theme || 'light'"/>

        <AudioPlayBack ref="audioPlayback" v-show="shouldShowAudioPlayer"/>
    </div>
</template>

<script>
    import Loading from "@/components/Loading";
    import HTMLParser from "@/utils/parser/html/dom";
    import BigPicture from "@/big-picture";
    import TourAnalytics from "@/analytics/tour";
    import ConversionWidget from "../../conversion";
    import { domGetElement } from "@/utils/dom-element-path";

    import { addStyles, injectCSS, parseAndConvertTourExternalLink, previewLockedStyles, removeLockedStyles, addScriptTagStringInHtml, onIframeReady, debounce } from "@/utils/index";
    import { renderVariables, shouldRenderVariables } from "@/utils/variables";
    
    import { createBuilderCollection } from '@/utils/visual-builder';

    import GuideDrawer from "../../guideDrawer";
    import AudioPlayBack from '../components/audio-playback';
    import WelcomeScreen from '../components/welcome-screen';

    const debounceFunction = debounce((callback) => callback(), 500);

    function createIframe(url = null){
        const iframe = document.createElement("iframe");

        iframe.setAttribute("class", "story-guide-iframe");

        if(url) iframe.src = url;

        return iframe;
    }

    function constructStyleGuide(type, tourStyleGuide, guideStyleGuide, override = false) {
        let styleGuide = {};

        switch (type) {
            case "guide": {
                styleGuide = {
                    guide: {
                        ...(tourStyleGuide?.guide || {}),
                        ...(override ? guideStyleGuide?.guide || {} : {}),
                    },
                    button: {
                        ...(tourStyleGuide?.button || {}),
                        ...(override ? guideStyleGuide?.button || {} : {})
                    },
                    glow: {
                        ...(tourStyleGuide?.glow || {}),
                    },
                    beacon: {
                        ...(tourStyleGuide?.beacon || {}),
                    }
                };

                break;
            }
            case "lightbox": {
                styleGuide = {
                    lightbox: {
                        ...(tourStyleGuide?.lightbox || {}),
                        ...(override ? guideStyleGuide?.lightbox || {} : {}),
                    },
                    button: {
                        ...(tourStyleGuide?.button || {}),
                        ...(override ? guideStyleGuide?.button || {} : {})
                    }
                };

                break;
            }
            case "link": {
                styleGuide = {
                    guide: {
                        ...(tourStyleGuide?.link || {}),
                        ...(override ? guideStyleGuide?.link || {} : {}),
                    },
                    glow: {
                        ...(tourStyleGuide?.glow || {}),
                    },
                    beacon: {
                        ...(tourStyleGuide?.beacon || {}),
                    }
                };

                break;
            }
            case "input": {
                styleGuide = {
                    guide: {
                        ...(tourStyleGuide?.guide || {}),
                        ...(override ? guideStyleGuide?.guide || {} : {}),
                    },
                    glow: {
                        ...(tourStyleGuide?.glow || {}),
                    },
                    beacon: {
                        ...(tourStyleGuide?.beacon || {}),
                    }
                };

              break;
            }
        }

        return styleGuide;
    }

    function setupGuides(iframe, guides, options){
        const { screenType = "html", getTour, getTourOperator, getAnalyticsInstance, getAnalyticsData, getConversionState } = options;

        const mainDocument = iframe.contentDocument;
        const mainWindow = iframe.contentWindow;

        const storyGuide = new mainWindow.StoryGuide();

        const guidesCount = guides.length;

        const tour = getTour();

        guides.forEach((guide, index) => {
            const isFirstGuideIndex = index == 0;
            const isLastGuideIndex = guidesCount - 1 == index;
            let autoAdvanceTimer = null;

            if(screenType != "html"){
                try {
                    const guideDrawerInstance = new GuideDrawer({
                         doc: mainDocument,
                    });
    
                    const guideElement = guideDrawerInstance.createGuideElement({
                        elementId: guide.meta.guide_element_id,
                        getPositionProps: () => {
                            return {
                                elementX: guide.meta.guide_element_x,
                                elementY: guide.meta.guide_element_y,
                                elementWidth: guide.meta.guide_element_width,
                                elementHeight: guide.meta.guide_element_height,
                                containerX: guide.meta.guide_element_container_x,
                                containerY: guide.meta.guide_element_container_y,
                                containerWidth: guide.meta.guide_element_container_width,
                                containerHeight: guide.meta.guide_element_container_height,
                            }
                        },
                        getContainer: () => {
                            return mainDocument.body.querySelector("#storyscale-screen-element");
                        },
                        className: "",
                    });
    
                    mainDocument.body.appendChild(guideElement);
                } catch (error) {
                    console.log(error);
                }
            }

            const styleGuide = constructStyleGuide(
                guide.type,
                tour.style_guide, 
                guide.style_guide, 
                guide.meta.override_styles == "1"
            );

            const guideButtonTemplate = guide.type !== "link" 
            ? guide.meta.template || "single_button"
            : guide.meta.template || "prompt";

            const attachToElement = domGetElement(guide.meta.elementPath, mainDocument)

            const contextInstance = {
                // Context
                getTotalNumberOfSteps(){
                    return getTourOperator().getTotalNumberOfSteps();
                },
                getCurrentStepNumber(){
                    return getTourOperator().getCurrentStepNumber();
                },
                getTourOperator: () => getTourOperator(),

                // Events
                onButtonClick(props){
                    if(props.on_click_action === "next"){
                        gotToNextStep(storyGuide);
                    } else if(props.on_click_action === "back"){
                        gotToPreviousStep(storyGuide);
                    } else if(props.on_click_action === "restart"){
                        restartTour();
                    }
                }
            }
            
            //setup tour audio
            if (guide.meta.audio_enabled == "1") {
                getTourOperator().audioPlayback()?.add(guide.id, guide.meta.audio_url);
            }

            // Welcome Screen
            if (!getTourOperator().self.welcomeSceenWasActive && getTourOperator().isTourAudioSettingsEnabled && isFirstGuideIndex && (guide.meta.audio_enabled == "1")) {
                getTourOperator().self.shouldShowWelcomeScreen = true;
                getTourOperator().self.shouldStartTourManually = true;
                getTourOperator().self.welcomeSceenWasActive = true;
            }

            let stepOptions = {
                id: guide.id,
                type: guide.type,
                original_id: guide.original_tour_guide_id,
                options: {
                    title: guide.meta.title,
                    description: renderVariables({
                        content: guide.meta.description,
                        variables: getTourOperator().variableMap
                    }),
                    overlay: guide.meta.overlay == "1" ? true : false,
                    overlayType: guide.meta.overlay_type,
                    overlayBlurIntensity: guide.meta.overlay_blur_intensity,
                    
                    trigger_hover: guide.meta.trigger_hover == '1' ? true : false,

                    // Glow    
                    glowEnabled: guide.meta.glow_enabled == '1' ? true : false,
                    glowType: guide.meta.glow_type,
                    glowColor: guide.meta.glow_color,

                    // Beacon    
                    beaconEnabled: guide.meta.beacon_enabled == '1',
                    beaconPositionX: guide.meta.beacon_position_x,
                    beaconPositionY: guide.meta.beacon_position_y,
                    beaconParentWidth: guide.meta.beacon_parent_width,
                    beaconParentHeight: guide.meta.beacon_parent_height,
                    beaconTransitionEnabled: guide.meta.beacon_transition_enabled == "1",
                    beaconType: guide.meta.beacon_type,
                    beaconColor: guide.meta.beacon_color,

                    // Back arrow
                    backArrowEnabled: guide.meta.back_arrow_enabled == "1",
                    backArrowType: guide.meta.back_arrow_type,
                    backArrowColor: guide.meta.back_arrow_color,
                    backArrowOnClick: (instance) => {
                        gotToPreviousStep(instance)
                    },

                    arrowEnabled: guide.meta.arrow_enabled ? guide.meta.arrow_enabled == "1" : true,
                    arrowPosition: guide.meta.arrow_position,
                    verticalOffset: guide.meta.vertical_offset,
                    horizontalOffset: guide.meta.horizontal_offset,

                    showConfetti: guide.meta.showConfetti == "1" ? true : false,
                    template: guideButtonTemplate,

                    width: guide.meta.width,
                    height: guide.meta.height,

                    // Visual builder
                    visualBuilderEnabled: guide.meta.visual_builder_enabled == "1",
                    visualBuilderAst: guide.meta.visual_builder_ast ? createBuilderCollection(JSON.parse(guide.meta.visual_builder_ast), contextInstance) : [],
                    
                    attachTo: {
                        element: attachToElement,
                        on: guide.meta.position || 'top'
                    },
                    getTotalNumberOfSteps(){
                        return getTourOperator().getTotalNumberOfSteps();
                    },
                    getCurrentStepNumber(){
                        return getTourOperator().getCurrentStepNumber();
                    },
                    getTourOperator: () => getTourOperator(),
                },
                styles: styleGuide,
                events: {
                    onShow(instance){
                        previewLockedStyles(attachToElement);

                        getTourOperator().onGuideShow(guide);
                        getAnalyticsInstance()?.addGuideViewEvent(getAnalyticsData());
                        getAnalyticsInstance()?.addToGuidesViewed(guide.id);
                        getAnalyticsInstance()?.startTimer(`guide_${guide.id}`);

                        if(instance) startAutoAdvance(instance);
                        
                        if (guide.meta.audio_enabled == "1" && getTourOperator().isTourAudioSettingsEnabled) {
                            getTourOperator().audioPlayback()?.pause();

                            debounceFunction(() => {
                                getTourOperator().audioPlayback()?.play(guide.id);
                            });
                        }

                        getTourOperator().self.shouldShowAudioPlayer = guide.meta.audio_enabled == "1" && getTourOperator().isTourAudioSettingsEnabled == true;
                    },
                    onHide(_instance, storyElement){
                        try {
                            getTourOperator().state.previousStep = storyElement;
                        } catch (error) {
                            console.log(error);
                        }

                        getAnalyticsInstance()?.addTotalTimeEvent(getAnalyticsData(), {
                            id: `guide_${guide.id}`,
                            type: "guide",
                        });

                        setTimeout(() => {
                            removeLockedStyles(attachToElement);
                        }, 200);

                        cleanupAutoAdvance();

                        getTourOperator().audioPlayback()?.pause();
                    }
                }
            };

            function startAutoAdvance(instance){
                if(guide.meta.auto_advance_enabled == "1" && guide.meta.auto_advance_duration){
                    const durationInMiliseconds = Number(guide.meta.auto_advance_duration) * 1000;

                    autoAdvanceTimer = setTimeout(() => {
                        if(guide.type == 'link'){
                            onLinkGuideElementClick();
                        } else {
                            gotToNextStep(instance);
                        }
                    }, durationInMiliseconds);
                }
            }

            function cleanupAutoAdvance(){
                clearTimeout(autoAdvanceTimer);
            }

            function gotToNextStep(instance){
                if(isLastGuideIndex){
                    const operator = getTourOperator();

                    if(operator.isLastScreen()){
                        getTourOperator()?.tourGuideInstance()?.next();    
                    } else {
                        operator.next();
                        operator.startTour();
                    }
                } else {
                    getTourOperator()?.tourGuideInstance()?.next();
                }
            }

            function gotToPreviousStep(instance){
                if(isFirstGuideIndex){
                    const operator = getTourOperator();

                    if(!operator.isFirstScreen()){
                        operator.back();
                        operator.startTourFromLast();
                    }
                } else {
                    getTourOperator()?.tourGuideInstance()?.back();
                }
            }

            function resetInputFieldValues(){
                const operator = getTourOperator();

                try {
                    operator.screens.forEach(screen => {
                        const fields = [...screen.iframe.contentDocument.querySelectorAll("input, select, textarea")];

                        fields.forEach(field => field.value = "")
                    });
                } catch (error) {
                    console.log(error); 
                }
            }

            function restartTour(){
                const operator = getTourOperator();

                resetInputFieldValues();

                operator.start();
            }

            const buttons = {
                button1: {
                    text: guide.meta.button1_text,
                    onClick(instance){
                        if(getConversionState().isConversionActive) return;

                        if(guideButtonTemplate === "prompt") return;

                        const guideAction = ["single_link_button", "two_link_buttons"].includes(guideButtonTemplate) ? "link" : guide.meta.button1_action || "next";

                        if(guideAction === "link"){
                            guide.meta.button1_external_url && window.open(parseAndConvertTourExternalLink(guide.meta.button1_external_url), "_blank");
                        } else if(guideAction === "next"){
                            gotToNextStep(instance)
                        } else if(guideAction === "back"){
                            gotToPreviousStep(instance);
                        } else if(guideAction === "restart"){
                            restartTour();
                        }
                    }
                },
                button2: {
                    text: guide.meta.button2_text,
                    onClick(instance){
                        if(getConversionState().isConversionActive) return;

                        if(["prompt", "single_button"].includes(guideButtonTemplate)) return;

                        const guideAction = ["single_link_button", "single_button_with_link", "two_link_buttons"].includes(guideButtonTemplate)  ? "link" : guide.meta.button2_action || "back";

                        if(guideAction === "link"){
                            guide.meta.button2_external_url && window.open(parseAndConvertTourExternalLink(guide.meta.button2_external_url), "_blank");
                        } else if(guideAction === "next"){
                            gotToNextStep(instance)
                        } else if(guideAction === "back"){
                            gotToPreviousStep(instance);
                        } else if(guideAction === "restart"){
                            restartTour();
                        }
                    }
                },
            };

            const linkDetails = {
                type: guide.meta.link_type,
                external_url: guide.meta.link_external_url || "",
                external_url_target: guide.meta.link_external_url_target || "_self",
                screen_id: Number(guide.meta.link_screen_id) || 0,
                step_id: Number(guide.meta.link_step_id) || 0,
                link_event: guide.meta.link_event || "click",
                link_event_hover_delay: Number(guide.meta.link_event_hover_delay || 0) * 1000,
            };

            function onLinkGuideElementClick(){
                if(getConversionState().isConversionActive) return;

                switch(linkDetails.type){
                    case "external": {
                        if(linkDetails.external_url) window.open(parseAndConvertTourExternalLink(linkDetails.external_url), linkDetails.external_url_target);
                        
                        cleanupAutoAdvance();

                        break;
                    }
                    case "screen": {
                        if(!linkDetails.screen_id) {
                            gotToNextStep();
                        } else {
                            const operator = getTourOperator();
                            operator.show(linkDetails.screen_id);
                            operator.startTour();
                        }

                        break;
                    }
                    case "step": {
                        const operator = getTourOperator();
                    
                        if(operator.currentScreen.id === linkDetails.screen_id){
                            operator.showGuide(linkDetails.step_id);
                        } else {
                            operator.show(linkDetails.screen_id);
                            
                            setTimeout(() => {
                                if(linkDetails.step_id){
                                    operator.showGuide(linkDetails.step_id);
                                } else {
                                    operator.startTour();
                                }
                            }, 500);
                        }
                    }
                }
            }

            // Guide & lightbox options
            if(["guide", "lightbox"].includes(guide.type)){
                stepOptions = {
                    ...stepOptions,
                    options: {
                        ...stepOptions.options,
                        buttons,
                        onGuideElementClick: (instance) => {
                            gotToNextStep(instance);
                        }
                    }
                }
            } else if(guide.type === "link"){
                stepOptions = {
                    ...stepOptions,
                    options: {
                        ...stepOptions.options,
                        link: linkDetails,
                        onElementEvent: onLinkGuideElementClick
                    }
                }
            } else if(guide.type === "input"){
                stepOptions = {
                    ...stepOptions,
                    options: {
                        ...stepOptions.options,
                        // Validation
                        validation: guide.meta.validation,
                        characterLength: guide.meta.character_length,
                        predefineValue: guide.meta.pre_define_value,
                        // Input field
                        placeholder: guide.meta.placeholder,
                        defaultValue: guide.meta.default_value,
                        // Selectbox
                        selectedValueOption: guide.meta.selected_value_option,
                        selectboxOptions: guide.meta.selectbox_options,
                        // Events
                        onSuccess(instance){
                          return new Promise((resolve) => {
                               gotToNextStep(instance);

                               resolve();
                          });
                        }
                    }
                }
            } else if(guide.type === "group"){
                // Please refactor
                const stepCollection = guide.guides.map((guideObject) => {
                    let options = {};

                    if(screenType != "html"){
                        try {
                            const guideDrawerInstance = new GuideDrawer({
                                doc: mainDocument,
                            });
            
                            const guideElement = guideDrawerInstance.createGuideElement({
                                elementId: guideObject.meta.guide_element_id,
                                getPositionProps: () => {
                                    return {
                                        elementX: guideObject.meta.guide_element_x,
                                        elementY: guideObject.meta.guide_element_y,
                                        elementWidth: guideObject.meta.guide_element_width,
                                        elementHeight: guideObject.meta.guide_element_height,
                                        containerX: guideObject.meta.guide_element_container_x,
                                        containerY: guideObject.meta.guide_element_container_y,
                                        containerWidth: guideObject.meta.guide_element_container_width,
                                        containerHeight: guideObject.meta.guide_element_container_height,
                                    }
                                },
                                getContainer: () => {
                                    return mainDocument.body.querySelector("#storyscale-screen-element");
                                },
                                className: "",
                            });
            
                            mainDocument.body.appendChild(guideElement);
                        } catch (error) {
                            console.log(error);
                        }
                    }
                    
                    const guideButtonTemplate = guideObject.type !== "link" 
                    ? guideObject.meta.template || "single_button"
                    : guideObject.meta.template || "prompt";

                    const buttons = {
                        button1: {
                            text: guideObject.meta.button1_text,
                            onClick(){
                                let instance = storyGuide;

                                if(guideButtonTemplate === "prompt") return;

                                const guideAction = guideObject.meta.button1_action || "next";

                                if(guideAction === "next"){
                                    gotToNextStep(instance)
                                } else if(guideAction === "back"){
                                    gotToPreviousStep(instance);
                                } else if(guideAction === "restart"){
                                    restartTour();
                                }
                            }
                        },
                        button2: {
                            text: guideObject.meta.button2_text,
                            onClick(){
                                let instance = storyGuide;

                                if(["prompt", "single_button"].includes(guideButtonTemplate)) return;

                                const guideAction = guideButtonTemplate === "single_button_with_link" ? "link" : guideObject.meta.button2_action || "back";

                                if(guideAction === "link"){
                                    guide.meta.button2_external_url && window.open(parseAndConvertTourExternalLink(guideObject.meta.button2_external_url), "_blank");
                                } else if(guideAction === "next"){
                                    gotToNextStep(instance)
                                } else if(guideAction === "back"){
                                    gotToPreviousStep(instance);
                                } else if(guideAction === "restart"){
                                    restartTour();
                                }
                            }
                        },
                    };

                    switch(guideObject.type){
                        case "guide": {                
                            options = {
                                type: "guide",
                                template: guideButtonTemplate,
                                buttons,
                                onGuideElementClick: (instance) => {
                                    gotToNextStep(instance);
                                }
                            };

                            break;
                        }
                        case "link": {
                            const linkDetails = {
                                type: guideObject.meta.link_type,
                                external_url: guideObject.meta.link_external_url || "",
                                external_url_target: guideObject.meta.link_external_url_target || "_self",
                                screen_id: Number(guideObject.meta.link_screen_id) || 0,
                                step_id: Number(guideObject.meta.link_step_id) || 0,
                                link_event: guideObject.meta.link_event || "click",
                                link_event_hover_delay: Number(guideObject.meta.link_event_hover_delay || 0) * 1000,
                            };

                            options = {
                                type: "link",
                                template: guideButtonTemplate,
                                link: linkDetails,
                                onElementEvent(){
                                    switch(this.link.type){
                                        case "external": {
                                            if(this.link.external_url) window.open(parseAndConvertTourExternalLink(this.link.external_url), this.link.external_url_target);
                                            break;
                                        }
                                        case "screen": {
                                            if(!this.link.screen_id) {
                                                gotToNextStep();
                                            } else {
                                                const operator = getTourOperator();
                                                operator.show(this.link.screen_id);
                                                operator.startTour();
                                            }

                                            break;
                                        }
                                        case "step": {
                                            const operator = getTourOperator();
                                            
                                            if(operator.currentScreen.id === this.link.screen_id){
                                                operator.showGuide(this.link.step_id);
                                            } else {
                                                operator.show(this.link.screen_id);
                                         
                                                setTimeout(() => {
                                                    if(this.link.step_id){
                                                        operator.showGuide(this.link.step_id);
                                                    } else {
                                                        operator.startTour();
                                                    }
                                                }, 500);
                                            }
                                        }
                                    }
                                }
                            };

                            break;
                        }
                    }

                    const attachToElement = domGetElement(guideObject.meta.elementPath, mainDocument);

                    const styleGuide = constructStyleGuide(
                        guideObject.type,
                        tour.style_guide, 
                        guideObject.style_guide, 
                        guideObject.meta.override_styles == "1"
                    );

                    return {
                        type: "guide",
                        
                        trigger_hover: guideObject.meta.trigger_hover == '1' ? true : false,
                        shouldScrollToPosition: false,
                        preview: true,
                        template: guideObject.meta.template,
                        title: guideObject.meta.title,
                        description: guideObject.meta.description,
                        overlay: guideObject.meta.overlay == '1' ? true : false,
                        overlayType: guideObject.meta.overlay_type,
                        overlayBlurIntensity: guideObject.meta.overlay_blur_intensity,
                    
                        // Glow    
                        glowEnabled: guideObject.meta.glow_enabled == '1',
                        glowType: guideObject.meta.glow_type,
                        glowColor: guideObject.meta.glow_color,

                        // Beacon    
                        beaconEnabled: guideObject.meta.beacon_enabled == '1',
                        beaconPositionX: guideObject.meta.beacon_position_x,
                        beaconPositionY: guideObject.meta.beacon_position_y,
                        beaconParentWidth: guideObject.meta.beacon_parent_width,
                        beaconParentHeight: guideObject.meta.beacon_parent_height,
                        beaconTransitionEnabled: guideObject.meta.beacon_transition_enabled == "1",
                        beaconType: guideObject.meta.beacon_type,
                        beaconColor: guideObject.meta.beacon_color,

                        // Back arrow
                        backArrowEnabled: guideObject.meta.back_arrow_enabled == "1",
                        backArrowType: guideObject.meta.back_arrow_type,
                        backArrowColor: guideObject.meta.back_arrow_color,
                        backArrowOnClick: (instance) => {
                            gotToPreviousStep(instance)
                        },

                        arrowEnabled: guideObject.meta.arrow_enabled ? guideObject.meta.arrow_enabled == "1" : true,
                        arrowPosition: guideObject.meta.arrow_position,
                        verticalOffset: guideObject.meta.vertical_offset,
                        horizontalOffset: guideObject.meta.horizontal_offset,

                        attachTo: {
                            element: attachToElement,
                            on: guideObject.meta.position || 'top'
                        },

                        width: guideObject.meta.width,
                        height: guideObject.meta.height,

                        // Visual builder
                        visualBuilderEnabled: guideObject.meta.visual_builder_enabled == "1",
                        visualBuilderAst: guideObject.meta.visual_builder_ast ? createBuilderCollection(JSON.parse(guideObject.meta.visual_builder_ast), contextInstance) : [],

                        buttons: {
                            button1: {
                                text: guideObject.meta.button1_text,
                                action: guideObject.meta.button1_action
                            },
                            button2: {
                                text: guideObject.meta.button2_text,
                                external_url: guideObject.meta.button2_external_url,
                                action: guideObject.meta.button2_action
                            }
                        },
                        getTotalNumberOfSteps(){
                            return getTourOperator().getTotalNumberOfSteps();
                        },
                        getCurrentStepNumber(){
                            return getTourOperator().getCurrentStepNumber();
                        },
                        styles: styleGuide,

                        ...options,
                    }
                }).filter(Boolean);
                
                stepOptions = {
                    ...stepOptions,
                    options: {
                        steps: stepCollection,
                        getTotalNumberOfSteps(){
                            return getTourOperator().getTotalNumberOfSteps();
                        },
                        getCurrentStepNumber(){
                            return getTourOperator().getCurrentStepNumber();
                        },
                        getTourOperator: () => getTourOperator(),
                    }
                }
            }

            storyGuide.addStep({
                ...stepOptions,
            });
        });

        return storyGuide;
    }

    function showIframe(iframe){
        iframe.classList.add("active");
    }

    function hideIframe(iframe){
        iframe.classList.remove("active");
    }

    export default {
        props: [],
        components: {
            Loading,
            AudioPlayBack,
            WelcomeScreen
        },
        data(){
            return {
                loading: true,
                operator: {},

                tour: {},
                customerData: {},
                delivery_type: "share",
                tour_type: "published",

                // Conversion
                conversion: null,
                isConversionActive: false,
                conversionInstance: null,

                // Screen loader
                screenLoadMap: new Map(),
                insights: {},

                // Tour audio
                shouldShowAudioPlayer: false,

                welcomeSceenWasActive: false,
                shouldShowWelcomeScreen: false,
                shouldStartTourManually: false,
            }
        },
        created () {
            if(this.getParameterByName('embed')){
                this.delivery_type = "embed"
            }

            if (this.getParameterByName('delivery')) {
                this.delivery_type = this.getParameterByName('delivery');
            }

            if(this.getParameterByName('type') == "personalised"){
                this.tour_type = "personalised"
            }

            injectCSS(`
                .story-guide-iframe{
                    width: 100%;
                    height: 100%;
                    border: none;
                    position: absolute;
                    left: -100%;
                }
                .story-guide-iframe.active{
                    left: 0px;
                }
            `);
        },
        destroyed(){
            this.removePreviews();
        },
        methods: {
            // Tour
            init({ tour, variableMap }){
                return new Promise((initResolve) => {
                    this.removePreviews();

                    const self = this;

                    this.tour = tour;
                    this.conversion = tour.conversion;
                    
                    try {
                        this.insights = JSON.parse(this.decodeBase64(this.getParameterByName('pvd')));
                    } catch (error) {
                        
                    }
                    
                    // Analytics
                    this.trackAnalytics = this.initAnalytics({
                        platform_customer_id: tour.platform_customer_id
                    });

                    // On page close / reload
                    window.onbeforeunload = () => {
                        this.trackAnalytics?.onClose(this.analyticsData());
                    }

                    const operator = {
                        state: {},
                        screens: [],
                        variableMap,
                        currentScreen: null,
                        currentIndex: null,
                        audioPlayback: () => self.$refs.audioPlayback,
                        self,
                        isTourAudioSettingsEnabled: this.tour?.is_audio_enabled,
                        tourGuideInstance(){
                            return this.currentScreen.tourGuide;
                        },
                        totalScreens(){
                            return this.screens.length;
                        },
                        isFirstScreen(){
                            return this.currentIndex == 0;
                        },
                        isLastScreen(){
                            return this.currentIndex == (this.totalScreens() - 1);
                        },
                        getScreen(screenId){
                            let screenIndex = null;

                            const screen = this.screens.find((item, index) => {
                                if(screenId == item.id){
                                    screenIndex = index;  

                                    return true;
                                }

                                return false;
                            });

                            return {
                                index: screenIndex,
                                screen
                            }
                        },
                        show(screenId, captureScreenView = true, shouldStartTour = true){
                            const { screen, index } = this.getScreen(screenId);

                            if(screen){
                                const prevScreen = this.currentScreen;

                                if(this.currentScreen){
                                    // Hide current step
                                    if(this.currentScreen.tourGuide.currentStep){
                                        this.currentScreen.tourGuide.currentStep.hide()
                                    }
                                }

                                this.currentIndex = index;
                                this.currentScreen = screen;

                                this.currentScreen.show({
                                    captureScreenView,
                                    beforeShow(){
                                        prevScreen && prevScreen.hide();
                                    }
                                }).then(() => shouldStartTour && this.startTour()).catch(console.log);
                            }
                        },
                        showGuide(stepId){
                            const { step } = this.currentScreen.tourGuide.getStep(stepId);
                        
                            if(step){
                                this.currentScreen.tourGuide.showStep(stepId);
                            } else {
                                this.startTour();
                            }
                        },
                        startTour(){
                            if(this.currentScreen && !self.shouldStartTourManually){
                                setTimeout(() => this.currentScreen.tourGuide?.start(), 500);
                            }
                        },
                        startTourFromLast(){
                            if(this.currentScreen){
                                setTimeout(() => this.currentScreen.tourGuide?.startFromLast(), 500);
                            }
                        },
                        next(){
                            if (this.currentScreen && !this.isLastScreen()) {
                                const nextIndex = this.currentIndex + 1;
                                const screen = this.screens[nextIndex];

                                if (screen) {
                                    const prevScreen = this.currentScreen;
                                    
                                    this.currentScreen.tourGuide.complete();

                                    this.currentIndex = nextIndex;
                                    this.currentScreen = screen;

                                    this.currentScreen.show({
                                        beforeShow(){
                                            prevScreen && prevScreen.hide();
                                        }
                                    }).then(() => this.startTour()).catch(console.log);
                                }
                            }
                        },
                        back(){
                            if (this.currentScreen) {
                                const backIndex = this.currentIndex - 1;
                                const screen = this.screens[backIndex]

                                if (screen) {
                                    const prevScreen = this.currentScreen;

                                    this.currentScreen.tourGuide.complete();

                                    this.currentIndex = backIndex;
                                    this.currentScreen = screen;

                                    this.currentScreen.show({
                                        beforeShow(){
                                            prevScreen && prevScreen.hide();
                                        }
                                    }).then().catch(console.log);
                                }
                            }
                        },
                        start(){
                            const screen = this.screens[0];

                            if(screen){
                                const prevScreen = this.currentScreen;

                                if(this.currentScreen){
                                    this.currentScreen.tourGuide.complete();
                                }
                            
                                this.currentIndex = 0;
                                this.currentScreen = screen;
                                
                                this.currentScreen.show({
                                    beforeShow(){
                                        prevScreen && prevScreen.hide();
                                    }
                                }).then(() => this.startTour()).catch(console.log)
                            }
                        },
                        getTotalNumberOfSteps(){
                            return this.screens.reduce((steps, screen) => steps + screen.guides.length, 0)
                        },
                        getCurrentStepNumber(){
                            let step = 0;
                            
                            for(const screen of this.screens){
                                if(this.currentScreen?.id == screen.id){
                                    step += screen.tourGuide?.currentIndex != null ? screen.tourGuide?.currentIndex + 1 : 0;
                                    
                                    break;
                                } else {
                                    step += screen.guides.length;
                                }
                            }

                            return step;
                        },
                        getCurrentState(){
                            const currentScreenId = this.currentScreen?.id;
                            const currentStepId = this.currentScreen?.tourGuide?.currentStep?.id;
                            const currentScreenIframe = this.currentScreen?.iframe;

                            return {
                                screenId: currentScreenId,
                                stepId: currentStepId,
                                iframe: currentScreenIframe,
                            }
                        },
                        // Events
                        onGuideShow(guide){
                            self.updateTourHistory(guide);
                            self.setupNavigationBasedConversion();
                        }
                    };

                    self.operator = operator;

                    initResolve();
                });
            },
            loadScreen(screen){
                const self = this;

                const screenIndex = self.operator.screens.findIndex(screenItem => screenItem.id == screen.id);

                if(screenIndex == -1) return Promise.reject();

                // Check if screen is already loading, return screen loader promise instance
                if(screen.loading) return Promise.resolve(self.screenLoadMap.get(screen.id));

                if(screen.isReady && screen.iframe) return Promise.resolve();

                // Set screen loading true
                self.operator.screens[screenIndex].loading = true;

                return new Promise(async (resolve, reject) => {
                    try {
                        let html = "";

                        try {
                            html = await self.fetchScreenContent(screen.html_file_url, screen.type || "html");
                        } catch (error) {
                            console.error(error);
                            
                            html = "";
                        }

                        const content = addScriptTagStringInHtml(process.env.VUE_APP_FRONTEND_APP_URL + "/story-guide/story-guide.js", html);
                        
                        const htmlBlob = new Blob([content], {type: "text/html"});

                        const htmlURL = URL.createObjectURL(htmlBlob);

                        const iframe = createIframe(htmlURL);

                        self.$refs.tourHolder.appendChild(iframe);

                        await HTMLParser.renderIframe({
                            iframe,
                        },(element) => {
                            if(element.tagName == "TEXT"){
                                if(shouldRenderVariables(element.innerText)){
                                    element.innerText = renderVariables({
                                        content: element.innerText,
                                        variables: self.operator.variableMap,
                                    });
                                }
                            }
                        });

                        const mainDocument = iframe.contentDocument;

                        const checkStoryGuideLoaded = () => {
                            const mainWindow = iframe.contentWindow;

                            if(mainWindow.StoryGuide){
                                const tourGuide = setupGuides(iframe, screen.guides, {
                                    screenType: screen.type || "html",
                                    getTour(){
                                        return self.tour;
                                    },
                                    getTourOperator(){
                                        return self.operator;
                                    },
                                    getAnalyticsInstance(){
                                        return self.trackAnalytics;
                                    },
                                    getAnalyticsData(){
                                        return self.analyticsData();
                                    },
                                    getConversionState(){
                                        return {
                                            isConversionActive: self.isConversionActive
                                        }
                                    }
                                });

                                // Delete screen loader promise instance
                                self.screenLoadMap.delete(screen.id);

                                self.operator.screens[screenIndex].loading = false;
                                self.operator.screens[screenIndex].isReady = true;
                                self.operator.screens[screenIndex].iframe = iframe;
                                self.operator.screens[screenIndex].tourGuide = tourGuide;

                                self.trackAnalytics?.setTotalGuides(self.operator.getTotalNumberOfSteps());

                                resolve();
                            } else {
                                window.requestAnimationFrame(checkStoryGuideLoaded);
                            }
                        }

                        await onIframeReady(iframe);

                        addStyles(mainDocument, `html, body { margin: 0; padding: 0;}`);

                        window.requestAnimationFrame(checkStoryGuideLoaded);
                    } catch (err) {
                        reject(err);
                    }
                })     
            },
            setScreens(screens){
                if(!this.operator) return;

                const self = this;

                this.operator.screens = screens.map((screen, index) => {
                    return {
                        id: screen.id,
                        original_id: screen.original_tour_screen_id,
                        html_file_url: screen.html_file_url,
                        guides: screen.guides,
                        name: screen.name,
                        type: screen.type,
                        order: screen.order,
                        isReady: false,
                        iframe: null,
                        tourGuide: null,
                        loading: false,
                        async show({captureScreenView = true, beforeShow = null} = {}){
                            return self.loadScreen(this).then(() => {
                                if(!this.iframe) return;

                                (beforeShow && typeof beforeShow == 'function') && beforeShow();

                                showIframe(this.iframe);

                                try {
                                    if(captureScreenView){
                                        self.trackAnalytics.addScreenViewEvent(self.analyticsData());
                                        self.trackAnalytics?.startTimer(`screen_${this.id}`);
                                        self.trackAnalytics.setupPageClickHandler(this.iframe.contentDocument);
                                    }
                                } catch (error) { console.log(error); }

                                // Load next screen
                                setTimeout(() => {
                                    const nextIndex = Math.min(index + 1, self.operator.screens.length - 1);

                                    const nextScreen = self.operator.screens[nextIndex];

                                    self.screenLoadMap.set(nextScreen.id, self.loadScreen(nextScreen));
                                }, 10);

                                self.loading = false;
                            }).catch(error => console.log(error));
                        },
                        hide(){
                            if(!this.iframe) return;

                            hideIframe(this.iframe);

                            try {
                                self.trackAnalytics.addTotalTimeEvent(self.analyticsData(), {
                                    id: `screen_${this.id}`,
                                    type: "screen",
                                });
                            } catch (error) { console.log(error); }
                        },
                    }
                }).sort((screenOne , screenTwo) => screenOne.order - screenTwo.order);
            },
            startTour(showLoading = true){
                this.loading = showLoading;

                this.operator?.start();

                // Create visitor event
                if(this.trackAnalytics) {
                    BigPicture.detailsPromise().then((customerData) => {
                        this.customerData = customerData;
                    }).finally(() => {
                        this.trackAnalytics?.startTimer(`tour_${this.analyticsData().tour.id}`);
                        this.trackAnalytics?.addVistorEvent(this.analyticsData());
                    });
                }

                this.setupTimeBasedConversion();
            },
            // Welcome Screen
            startTourManually() {
                this.shouldStartTourManually = false;
                this.shouldShowWelcomeScreen = false;
                this.startTour(false);
            },
            showScreen(screenId){
                this.operator?.show(screenId, false, false);
            },
            removePreviews(){
                this.operator?.screens?.forEach((screen) => screen.iframe.remove())
            },
            async fetchScreenContent(fileUrl, screenType = "html"){
                if(screenType == "html"){
                    return fetch(fileUrl).then(res => res.text())
                }

                return HTMLParser.toString(HTMLParser.generateAST({
                    type: screenType,
                    content_url: fileUrl,
                }));
            },
            // Conversion
            isConversionEnabled(){
              return Boolean(this.tour.conversion_enabled && this.conversion && this.conversion.primary_cta);
            },
            onConversionSubmit(){
                this.isConversionActive = false;

                this.trackAnalytics?.addConversionEvent(this.analyticsData());

                setTimeout(() => {
                    this.conversionInstance.hide();
                }, 1000);
            },
            onConversionClose(){
                this.isConversionActive = false;
                this.conversionInstance.hide();
            },
            setupTimeBasedConversion(){
                const self = this;

                if(this.isConversionEnabled() && this.conversion.trigger_type === "time-based"){
                    const waitTime = this.conversion.wait_time;
                    const ctaCode = this.conversion.primary_cta.text;
                    const conversionType = this.conversion.conversion_type;
                    const isPaywallConversion = conversionType == "paywall";

                    self.conversionInstance = new ConversionWidget({
                        root: document.body,
                        onClose(){
                            self.onConversionClose();
                        },
                        onSubmit(){
                            self.onConversionSubmit();
                        },
                        closeButton: !isPaywallConversion,
                    });

                    setTimeout(() => {
                        self.isConversionActive = true;
                        self.conversionInstance.show(ctaCode);
                    }, waitTime * 1000);
                }
            },
            setupNavigationBasedConversion(){
                const self = this;

                if(this.isConversionEnabled() && this.conversion.trigger_type === "navigation"){
                    const { screenId, stepId } = this.operator.getCurrentState();
                    const { tour_screen_id, tour_guide_id } = this.conversion;

                    if(screenId === tour_screen_id && stepId === tour_guide_id){
                        const ctaCode = this.conversion.primary_cta.text;

                        self.conversionInstance = new ConversionWidget({
                            root: document.body,
                            onClose(){
                                self.onConversionClose();
                            },
                            onSubmit(){
                                self.onConversionSubmit();
                            }
                        });

                        setTimeout(() => {
                            self.isConversionActive = true;
                            self.conversionInstance.show(ctaCode);
                        }, 100);
                    }
                }
            },
            // History
            updateTourHistory(guide){
                try {
                    const currentStepNumber = this.operator.getCurrentStepNumber();
                    const totalNumberOfSteps = this.operator.getTotalNumberOfSteps();
                    const title = this.slugify(guide.meta.name ? guide.meta.name : guide.meta.title);
                    const type = guide.type;
                    const stepQueryParam = [currentStepNumber, type, title].filter(Boolean).join("|")
    
                    if(totalNumberOfSteps == currentStepNumber){
                        this.trackAnalytics?.onClose(this.analyticsData());
                    }
                  
                    this.setQueryParameter("step", stepQueryParam);
                } catch (error) {
                    console.log(error);
                }
            },
            // Anayltics
            initAnalytics(data){
                return new TourAnalytics({
                    platform_customer_id: data.platform_customer_id,
                    dev: false,
                    logging: false,
                });
            },
            analyticsData() {
                const property = this.tour_type == "personalised" ? "id" : "original_id";
                const tourProperty = this.tour_type == "personalised" ? "id" : "original_tour_id";
                
                return {
                    tour: {
                        id: parseInt(this.tour?.[tourProperty]),
                        name: this.tour.name,
                        tour_type: this.tour_type
                    },
                    tour_screen: {
                        id: parseInt(this.operator.currentScreen?.[property]),
                        name: this.operator.currentScreen?.name || "",
                        screen_type: this.operator.currentScreen?.type || "",
                    },
                    tour_guide: {
                        id: parseInt(this.operator.currentScreen?.tourGuide?.currentStep?.[property] || 0),
                        guide_type: this.operator.currentScreen?.tourGuide?.currentStep?.type || ""
                    },
                    delivery_type: this.delivery_type,
                    customer: this.customerData,
                    user: {
                        email: this.insights.email
                    }
                }
            },
        }
    }
</script>