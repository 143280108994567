<template>
  <div class="storyscale-audio-container">
    <div class="storyscale-audio-playback" :class="{ 'audio-pulse': isPlaying }" @click="toggle" title="Play/Pause" role="button" aria-label="Play or pause audio" tabindex="0">
      <svg v-if="!isPlaying" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" role="img" aria-hidden="true">
        <path fill="currentColor" d="M8 17.175V6.825q0-.425.3-.713t.7-.287q.125 0 .263.037t.262.113l8.15 5.175q.225.15.338.375t.112.475q0 .25-.112.475t-.338.375l-8.15 5.175q-.125.075-.262.113T9 18.175q-.4 0-.7-.288t-.3-.712m2-1.825L15.25 12L10 8.65z"/>
      </svg>
      <svg v-else xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" role="img" aria-hidden="true">
        <path fill="currentColor" d="M14.5 18q-.402 0-.701-.299q-.299-.3-.299-.701V7q0-.402.299-.701q.3-.299.701-.299H17q.402 0 .701.299q.299.3.299.701v10q0 .402-.299.701q-.3.299-.701.299zM7 18q-.402 0-.701-.299Q6 17.401 6 17V7q0-.402.299-.701Q6.599 6 7 6h2.5q.402 0 .701.299q.299.3.299.701v10q0 .402-.299.701q-.3.299-.701.299zm7.5-1H17V7h-2.5zM7 17h2.5V7H7zM7 7v10zm7.5 0v10z"/>
      </svg>
      <svg class="progress-ring" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="progressbar" aria-valuemin="0" aria-valuemax="100" :aria-valuenow="progress">
        <circle class="progress-ring-circle" :style="{ strokeDasharray: circumference, strokeDashoffset: dashOffset }" cx="12" cy="12" r="10"/>
      </svg>
    </div>
    <div class="storyscale-mute-audio-playback" @click="toggleMute" title="Toggle Mute" role="button" aria-label="Toggle audio mute" tabindex="0">
      <div v-if="!isMute">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" role="img" aria-hidden="true"><path fill="currentColor" d="M11.553 3.064A.75.75 0 0 1 12 3.75v16.5a.75.75 0 0 1-1.255.555L5.46 16H2.75A1.75 1.75 0 0 1 1 14.25v-4.5C1 8.784 1.784 8 2.75 8h2.71l5.285-4.805a.752.752 0 0 1 .808-.13ZM10.5 5.445l-4.245 3.86a.748.748 0 0 1-.505.195h-3a.25.25 0 0 0-.25.25v4.5c0 .138.112.25.25.25h3c.187 0 .367.069.505.195l4.245 3.86Zm8.218-1.223a.75.75 0 0 1 1.06 0c4.296 4.296 4.296 11.26 0 15.556a.75.75 0 0 1-1.06-1.06a9.5 9.5 0 0 0 0-13.436a.75.75 0 0 1 0-1.06"/><path fill="currentColor" d="M16.243 7.757a.75.75 0 1 0-1.061 1.061a4.5 4.5 0 0 1 0 6.364a.75.75 0 0 0 1.06 1.06a6 6 0 0 0 0-8.485Z"/></svg>
      </div>
      <div v-else>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" role="img" aria-hidden="true"><path fill="currentColor" d="M7.83 11H5v2h2.83L10 15.17v-3.76l-1.29-1.29z" opacity=".3"/><path fill="currentColor" d="M4.34 2.93L2.93 4.34L7.29 8.7L7 9H3v6h4l5 5v-6.59l4.18 4.18c-.65.49-1.38.88-2.18 1.11v2.06a8.94 8.94 0 0 0 3.61-1.75l2.05 2.05l1.41-1.41zM10 15.17L7.83 13H5v-2h2.83l.88-.88L10 11.41zM19 12c0 .82-.15 1.61-.41 2.34l1.53 1.53c.56-1.17.88-2.48.88-3.87c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71m-7-8l-1.88 1.88L12 7.76zm4.5 8A4.5 4.5 0 0 0 14 7.97v1.79l2.48 2.48c.01-.08.02-.16.02-.24"/></svg>
      </div>
    </div>
    <div class="storyscale-caption-toggle" @click="toggleCaptions" title="Toggle Captions" role="button" aria-label="Toggle captions" tabindex="0">
      <div v-if="!showCaptions">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" role="img" aria-hidden="true"><path fill="currentColor" d="m20.475 23.3l-3.3-3.3H5q-.825 0-1.412-.587T3 18V5.825L.675 3.5L2.1 2.075l19.8 19.8zM21 18.125l-3.35-3.35q.175-.125.263-.337T18 14v-1h-1.5v.5h-.125L14.5 11.625V10.5h2v.5H18v-1q0-.425-.288-.712T17 9h-3q-.425 0-.712.288T13 10v.125L6.875 4H19q.825 0 1.413.588T21 6zM7 15h3q.425 0 .713-.288T11 14v-.175L10.175 13H9.5v.5h-2v-3.175L6.375 9.2q-.175.125-.275.338T6 10v4q0 .425.288.713T7 15"/></svg>
      </div>
      <div v-else>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" role="img" aria-hidden="true"><path fill="currentColor" d="M5 20q-.825 0-1.412-.587T3 18V6q0-.825.588-1.412T5 4h14q.825 0 1.413.588T21 6v12q0 .825-.587 1.413T19 20zm2-5h3q.425 0 .713-.288T11 14v-1H9.5v.5h-2v-3h2v.5H11v-1q0-.425-.288-.712T10 9H7q-.425 0-.712.288T6 10v4q0 .425.288.713T7 15m7 0h3q.425 0 .713-.288T18 14v-1h-1.5v.5h-2v-3h2v.5H18v-1q0-.425-.288-.712T17 9h-3q-.425 0-.712.288T13 10v4q0 .425.288.713T14 15"/></svg>
      </div>
    </div>
  </div>   
</template>

<script>
    import AudioManager from '../../services/audio-manager';
    import { createProgress } from '../../../../../utils';

    export default {
        name: "AudioPlayBack",
        props: ["onChange"],
        data(){
            return {
                isPlaying: false,
                audioManager: new AudioManager({
                    captionsEnabled: localStorage.getItem('storyscale-captions') === 'true'
                }),
                audioDuration: 0,
                currentSelectedAudioId: 0,
                
                progress: 0, // Progress value, should be between 0 and 100
                circumference: 2 * Math.PI * 10,

                stopProgress: null, // Progress value
                isMute: localStorage.getItem('storyscale-mute') === 'true',
                showCaptions: localStorage.getItem('storyscale-captions') === 'true'
            }
        },
        computed: {
            dashOffset() {
                return this.circumference * (1 - this.progress / 100);
            },
        },
        methods: {
            toggle(){
                this.isPlaying = !this.isPlaying;

                this.isPlaying ? this.playAudio() : this.pause();
            },
            onEnd(){
                this.pause();
            },
            onStart() {
                this.isPlaying = true;

                this.audioDuration = this.audioManager.getDuration();

                this.stopProgress = createProgress(this.audioDuration || 0, (currentProgress) => {
                    this.progress = currentProgress;
                });
            },
            play(id){
                this.currentSelectedAudioId = id;
                
                this.playAudio();
            },
            pause(){
                this.stopProgress && this.stopProgress();
                
                this.audioManager.pause();

                this.isPlaying = false;

                if (!this.isPlaying) {
                    
                    this.$nextTick(() => {
                        this.progress = 0;
                    });
                }
            },
            add(id, url, text){
                this.audioManager.add(id, url, text);
            },
            playAudio() {
                this.audioManager.play(this.currentSelectedAudioId, {
                    onEnd: () => {
                        this.onEnd();
                    },
                    onStart: () => {
                        this.onStart();
                    }
                });

                this.isPlaying = true;
            },
            toggleMute() {
                this.isMute = !this.isMute;
                localStorage.setItem('storyscale-mute', this.isMute);
                this.isMute ? this.audioManager.mute() : this.audioManager.unMute();
            },
            toggleCaptions() {
                this.showCaptions = !this.showCaptions;
                localStorage.setItem('storyscale-captions', this.showCaptions);
                this.showCaptions ? this.audioManager.enableCaptions() : this.audioManager.disableCaptions();
            }
        },
        watch: {
            isPlaying(){
                this.$emit("onChange", this.isPlaying);
            }
        }
    }
</script>

<style>
.storyscale-audio-container{
  display: flex;
  align-items: center;
  gap: 10px;
  position: fixed;
  top: 2%;
  right: 2%;
  z-index: 2147482646;
}
.storyscale-audio-playback {
  position: relative;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.progress-ring {
  position: absolute;
  width: 100%;
  height: 100%;
}

.progress-ring-circle {
  fill: none;
  stroke: #09ff00; /* Change the color of the progress bar here */
  stroke-width: 2; /* Change the thickness of the progress bar here */
  stroke-dasharray: 0; /* Initially no progress */
  transition: stroke-dasharray 0.3s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.audio-pulse:before,
.audio-pulse:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #ffff;
  border-radius: 50%;
  z-index: -1000;
  opacity: 0.7;
}

.audio-pulse:before {
  animation: audio-pulse 2s ease-out infinite;
}

.audio-pulse:after {
  animation: audio-pulse 2s 1s ease-out infinite;
}

.storyscale-mute-audio-playback {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.storyscale-mute-audio-playback svg {
  width: 16px;
  height: 16px;
}

.storyscale-caption-toggle {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.storyscale-caption-toggle svg {
  width: 16px;
  height: 16px;
}

@keyframes audio-pulse {
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.storyscale-caption-container {
  position: fixed;
  left: 50%;
  bottom: 10%;
  transform: translate(-50%, 0);
  text-align: center;
  z-index: 1000;
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  color: #ffffff;
  background: rgba(0, 0, 0, 0.95);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, sans-serif;
  line-height: 1.5;
  max-width: min(90%, 50rem);
  backdrop-filter: blur(4px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  pointer-events: none;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 2147483647;
}
@media screen and (max-width: 480px) {
  .storyscale-caption-container {
    font-size: 0.9rem;
    padding: 0.25rem 0.5rem;
    width: max-content;
  }
}
</style>