<template>
    <div>
        <!-- <lightDrag v-if="rptn && clfn && clfn === 'viewer' && dataObj && show" :obj="dataObj" /> -->
        <lightViewer v-if="rptn && clfn && clfn === 'viewer' && dataObj && show" :obj="dataObj" :showSequenceId="showSequenceId" :showAssetId="showAssetId" />
        <spotlightLight v-if="rptn && clfn && clfn === 'spotlight' && dataObj && show" :obj="dataObj" :showSequenceId="showSequenceId" :showAssetId="showAssetId"/>
        <Badge v-if="rptn && clfn && clfn === 'badge' && dataObj && show" :obj="dataObj" />
        <RecommendLight v-if="rptn && clfn && clfn === 'recommend' && dataObj && show" :obj="dataObj" />
        <Tour v-if="rptn && clfn && clfn === 'tour' && show" :tourId="tokenData || ''" />
        
        <!-- Deep Linking -->
        <!-- <lightViewer
        v-if="token && experienceType && experienceType === 'viewer' && dataObj && show"
        :obj="dataObj"
        :showSequenceId="showSequenceId"
        :showAssetId="showAssetId" /> -->

        <!-- <spotlightLight
        v-if="token && experienceType && experienceType === 'spotlight' && dataObj && show"
        :obj="dataObj"
        :showSequenceId="showSequenceId"
        :showAssetId="showAssetId"/> -->
        <!-- Deep Linking -->
    </div>
</template>
<script>
    import lightViewer from "./viewer/light";
    import spotlightLight from "./spotlight/spotlightLight";
    import Badge from "./badge/badge";
    import RecommendLight from "./recommend/recommendLight";
    import Tour from "../tour/optimized";

    export default {
        props: [ 'clfn', 'rptn' ],
        components: {
            lightViewer,
            spotlightLight,
            Badge,
            RecommendLight,
            Tour,
        },
        created () {
            if (this.clfn && this.rptn) {
                if(this.clfn == "tour"){
                    if(this.rptn.length > 45){
                        this.tokenData = JSON.parse(window.atob(this.rptn))?.id || ""
                    } else {
                        this.tokenData = this.rptn
                    }

                    this.show = true;
                } else {
                    // Deep Linking
                        this.getDataQueryParams()
                    // Deep Linking

                    this.getExperienceDetails(this.rptn);
                }
            }

            // Deep Linking (Path parameters)
            // this.getDataParams();

            // if (this.token) {
            //     this.getExperienceDetails(this.token);
            // }
            // Deep Linking
        },
        data () {
            return {
                dataObj: null,
                show: false,
                tokenData: {},
                // Deep linking
                token: null,
                sequenceName: null,
                assetName: null,
                showSequenceId: 0,
                showAssetId: 0,
                experienceType: null,
                // Deep linking
            };
        },
        methods: {
            getDataQueryParams(){
               this.sequenceName = this.getParameterByName('sequence');
               this.assetName = this.getParameterByName('asset');
            },
            getDataParams(){
               //URL: https://host/<experience_type>/<token>/<sequence_name:slug>/<asset_name:slug>

               const [experienceType , token, sequenceName, assetName] = location.pathname.substring(1).split("/");

               this.experienceType = experienceType;
               this.token = token;
               this.sequenceName = sequenceName;
               this.assetName = assetName;
            },
            getExperienceDetails ( token ) {
                this.$Progress.start();
                this.axios.get( `frontend/view-experience?encoded_input=${ token }` )
                    .then( ( response ) => {
                        const { data } = response.data;
                        if ( this.clfn) {
                            const exp = data.experience;
                            this.dataObj = {
                                ...exp,
                                display_pages: [],
                                paywall_cta_id: data.paywall_cta,
                                primary_cta_id: data.primary_cta,
                                nudge_cta_id: data.nudge_details,
                                style_guide_id: data.style_guide,
                                tracking_integrations: data.tracking_integrations,
                                primary_sequence: null,
                                sequences: [],
                                keen_data: data.user.keen_data,
                            };
                            if ( this.dtp ) {
                                this.dataObj.delivery_type = this.dtp;
                            }
                            if ( data.display_pages.length > 0 ) {
                                data.display_pages.map( ( link ) => {
                                    this.dataObj.display_pages.push( link.page_url );
                                    return link;
                                } );
                            }
                            this.dataObj.visitor_scroll_before_interaction = String( this.dataObj.visitor_scroll_before_interaction );
                            this.dataObj.visitor_wait_before_interaction = String( this.dataObj.visitor_wait_before_interaction );
                            if ( data.sequences ) {
                                data.sequences.map((sq) => {
                                    // sq.now = false;
                                    if (sq.pivot.is_primary === 1) {
                                        // sq.now = true;
                                        this.dataObj.primary_sequence = {...sq};
                                    } else {
                                        this.dataObj.sequences.push({...sq});
                                    }
                                    return sq;
                                });
                            }
                            switch ( this.clfn ) {
                                case 'viewer':
                                    this.dataObj.user = data.user;
                                    break;
                                case 'spotlight':
                                    if ( data.experience.launch_with_splash ) {
                                        this.dataObj.splash_asset_id = data.splash_image;
                                    } else {
                                        this.dataObj.splash_asset_id = null;
                                    }
                                    break;
                                case 'badge':
                                    this.dataObj.badge_asset_id = data.badge_asset;
                                    this.dataObj.badge_url = data.badge_url;
                                    break;
                                case 'recommend':
                                    this.dataObj.recommend_experience = data.recommend_experience ? data.recommend_experience : null;
                                    this.dataObj.recommend_experience_links = data.recommend_experience_links.length > 0 ? data.recommend_experience_links : [];
                                    this.dataObj.recommend_type = data.recommend_type;
                                    break;
                                case 'blank':
                                    break;
                                default:
                                    console.log( "Invalid Type" );
                            }
                            if ( this.dataObj.tracking_integrations.integrate_google_tag_manager ) {
                                this.integrateTagManager( this.dataObj.tracking_integrations.google_tag_manager_id );
                            }
                            // Deep Linking
                            let showSequence = {};

                            if(this.sequenceName){
                                const sequenceName = this.slugify(this.sequenceName);

                                showSequence = data.sequences.find((sequence) => this.slugify(sequence.sequence_name) == sequenceName);

                                if(showSequence && showSequence.id){
                                    this.showSequenceId = showSequence.id
                                }else{
                                    if(data.sequences[0] && data.sequences[0].id){
                                        this.showSequenceId = data.sequences[0].id
                                        showSequence = data.sequences[0];
                                    }
                                }
                            }

                            if(this.assetName){
                                const assetName = this.slugify(this.assetName);

                                const showAsset = showSequence.assets.find((asset) => this.slugify(asset.asset_friendly_name) == assetName);

                                if(showAsset && showAsset.id){
                                    this.showAssetId = showAsset.id
                                }
                            }
                            // Deep Linking

                            this.show = true;
                        }
                        this.$Progress.finish();
                    })
                    .catch( ( error ) => {
                        console.log(error);
                        const { message } = error.response.data;
                        console.log( message );
                        this.$Progress.fail();
                    } )
            },
        },
    }
</script>
