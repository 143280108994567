const ELEMENT_LOCKED_STYLE_TAG_KEY = "__storyscale_locked_styles_tag__";
const ELEMENT_LOCKED_STYLE_ATTRIBUTE_KEY = "storyscale-locked-styles";

export function addScriptTag(doc, link, options = {}) {
    const { module = false, defer = false, container = "head" } = options;

    const script = document.createElement('script');

    script.setAttribute('src', link);

    Boolean(module) && script.setAttribute('type', 'module');
    Boolean(defer) && script.setAttribute('defer', '');

    doc.querySelector(container).appendChild(script);

    return script;
}

export function addStyles(element, text) {
    const styleTag = document.createElement('style');

    styleTag.innerText = text

    element.querySelector("head").appendChild(styleTag);
}

export function injectCSS(code = "", id = null, doc = document) {
    return new Promise(function (resolve, reject) {
        function appendStyleTag(code = "", id = null) {
            const styleTag = doc.createElement('style');

            styleTag.innerText = "";
            styleTag.append(code)

            if (id) styleTag.id = id;

            doc.head.append(styleTag);

            return styleTag;
        }

        let styleTag = null;

        const cssCode = String(code);

        try {
            if (id) {
                styleTag = doc.getElementById(id);

                if (styleTag && styleTag.tagName == "STYLE") {
                    styleTag.innerText = "";
                    styleTag.append(cssCode)
                } else {
                    styleTag = appendStyleTag(cssCode, id)
                }
            } else {
                styleTag = appendStyleTag(cssCode)
            }

            resolve(styleTag);
        } catch (err) {
            reject(err)
        }
    });
}

export function isFunction(item = null) {
    return typeof item === 'function';
}

export function onIframeReady(iframe, state = "complete") {
    return new Promise((resolve, reject) => {
        const timer = setInterval(() => {
            try {
                if ([state, "complete", "interactive"].includes(iframe?.contentDocument?.readyState)) {
                    clearInterval(timer);
                    resolve();
                }
            } catch (err) {
                clearInterval(timer);
                console.log(err);
                reject()
            }
        }, 50);
    })
}

export function adjustIframeHeight(iframe, extraHeightPadding = 20) {
    return onIframeReady(iframe).then(() => {
        iframe.style.height = iframe.contentWindow.document.body.scrollHeight + extraHeightPadding + "px";

        return;
    });
}

export function show(element) {
    if (element instanceof HTMLElement) {
        element.style.removeProperty("display");
    }
}

export function hide(element) {
    if (element instanceof HTMLElement) {
        element.style.display = "none";
    }
}

export function parseAndReplaceTemplate(str = {}, replaceWith = null) {
    const result = str.replace(/{{(.*?)}}/gi, (_match, key) => {
        let value = "";

        try {
            value = replaceWith(key) || "";
        } catch (error) {
            console.log(error)
        }

        return value;
    });

    return result;
}

export function setDynamicQueryParamters(url = "", params = {}) {
    const result = parseAndReplaceTemplate(url, (key) => {
        if (!key) return "";

        const [dynamicKey = "", defaultValue = ""] = key.split("|");
        const value = params[dynamicKey.trim()] ?? defaultValue.trim();

        return value;
    });

    return result;
}

export function isValidHttpUrl(string = "") {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

export function isValidHostname(value) {
    if (typeof value !== 'string') return false

    const validHostnameChars = /^[a-zA-Z0-9-.]{1,253}\.?$/g
    if (!validHostnameChars.test(value)) {
        return false
    }

    if (value.endsWith('.')) {
        value = value.slice(0, value.length - 1)
    }

    if (value.length > 253) {
        return false
    }

    const labels = value.split('.')

    const isValid = labels.every(function (label) {
        const validLabelChars = /^([a-zA-Z0-9-]+)$/g

        const validLabel = (
            validLabelChars.test(label) &&
            label.length < 64 &&
            !label.startsWith('-') &&
            !label.endsWith('-')
        )

        return validLabel
    })

    return isValid
}

export function convertToHttpUrl(string = "") {
    if (!isValidHttpUrl(string)) {
        const url = `https://${string}`;

        return isValidHttpUrl(url) ? url : "";
    }

    return string;
}

export function parseAndConvertTourExternalLink(url = "") {
    const fullUrl = convertToHttpUrl(url);

    const params = queryParams(); // TODO: Check if inside an iframe

    const finalExternalLink = setDynamicQueryParamters(fullUrl, params);

    return finalExternalLink;
}

export function getBoundingClientRectPadded(element, padding = 20) {
    const rect = element.getBoundingClientRect();

    return {
        width: rect.width,
        height: rect.height,
        top: rect.top - padding,
        bottom: rect.bottom + padding,
        left: rect.left - padding,
        right: rect.right + padding,
    }
}

export function progress(initVal, lastVal, duration, callback) {
    let startTime = null;

    //get the current timestamp and assign it to the currentTime letiable
    let currentTime = Date.now();

    //pass the current timestamp to the step function
    const step = (currentTime) => {

        //if the start time is null, assign the current time to startTime
        if (!startTime) {
            startTime = currentTime;
        }

        //calculate the value to be used in calculating the number to be displayed
        const progress = Math.min((currentTime - startTime) / duration, 1);

        //calculate what to be displayed using the value gotten above
        callback(progress * (lastVal - initVal) + initVal);

        //checking to make sure the counter does not exceed the last value (lastVal)
        if (progress < 1) {
            window.requestAnimationFrame(step);
        }
        else {
            window.cancelAnimationFrame(window.requestAnimationFrame(step));
        }
    };

    //start animating
    window.requestAnimationFrame(step);
}

export function queryParams(queryString = null) {
    let result = {};

    try {
        const params = new URLSearchParams(queryString || window.location.search);

        result = Array.from(params.keys()).reduce((acc, val) => ({ ...acc, [val]: params.get(val) }), {});
    } catch (error) {
        console.log(error);
    }

    return result
}

export function getCssUnit(value = 0) {
    const result = {
        value: parseInt(value),
        unit: null,
    }

    let len = value.length

    if (!value || !len) return result

    let i = len;

    while (i--) {
        if (!isNaN(value[i])) {
            const unit = value.slice(i + 1, len) || null;
            result.unit = unit;

            return result;
        }
    }

    return result
}

export function previewLockedStyles(element) {
    if (!element) return;

    const styles = element.getAttribute(ELEMENT_LOCKED_STYLE_ATTRIBUTE_KEY);

    if (styles) injectCSS(styles, null, element.ownerDocument).then((tag) => element[ELEMENT_LOCKED_STYLE_TAG_KEY] = tag);
}

export function removeLockedStyles(element) {
    if (!element) return;

    const styleTag = element[ELEMENT_LOCKED_STYLE_TAG_KEY];

    if (styleTag) {
        try {
            styleTag.remove();

            element[ELEMENT_LOCKED_STYLE_TAG_KEY] = null;

            delete element[ELEMENT_LOCKED_STYLE_TAG_KEY];
        } catch (error) { console.log(error); }
    }
}

export function addToHeadStart(content, html) {
    const HEAD_TAG = "<head>";

    const headEndIndex = html.indexOf(HEAD_TAG) + HEAD_TAG.length;

    if (headEndIndex < 0) return "<head>" + content + "</head>" + html;

    return html.slice(0, headEndIndex) + content + html.slice(headEndIndex);
}

export function addScriptTagStringInHtml(url, html) {
    const doc = new DOMParser().parseFromString(html, "text/html");

    addScriptTag(doc, url, { container: "head", defer: true });

    return doc.documentElement.outerHTML;
}

export function parseObjectString(objectString) {
    const objectArray = objectString.split(";");

    const result = objectArray.reduce((acc, item) => {
        const [property, value] = item.split(":");

        if (property && value) acc[property.trim()] = value.trim();

        return acc;
    }, {});

    return result
}

export function getCookies() {
    let pairs = document.cookie.split(";");
    let cookies = {};

    try {
        for (let i = 0; i < pairs.length; i++) {
            let pair = pairs[i].split("=");

            cookies[(pair[0] + '').trim()] = window.unescape(pair.slice(1).join('='));
        }
    } catch (error) {
        console.log(error);
    }

    return cookies;
}

export function filterObject(obj = {}, predicate) {
    return Object.keys(obj)
        .filter(key => predicate(obj[key]))
        .reduce((res, key) => (res[key] = obj[key], res), {});
}

export function htmlToElement(string = "", mainDocument = document) {
    const element = mainDocument.createElement("div");

    element.innerHTML = string;

    return element.firstElementChild;
}

export function generateStyleString(styleObject, isImportant = false) {
    return Object.entries(styleObject).map(([property, value]) => `${property}: ${value} ${isImportant ? '!important' : ''}`).join(";")
}

export function createProgress(durationInSeconds = 0, callback) {
    let start = null;
    let currentValue = 0;
    const incrementValue = 100 / (durationInSeconds * 1000); // Calculate the increment value based on duration in seconds
    let state = {
        stopProgress: false
    };

    function animate(timestamp) {
        if (state.stopProgress) return;

        if (!start) start = timestamp;
        const progress = timestamp - start;
        currentValue = Math.min(progress * incrementValue, 100);

        callback(currentValue);

        if (progress < durationInSeconds * 1000) {
            requestAnimationFrame(animate);
        } else {
            // console.log("Reached 100!");
        }
    }

    requestAnimationFrame(animate);

    return () => {
        state.stopProgress = true;
    }
}

export function debounce(func, wait) {
    let timeout;

    return function (...args) {
        const context = this;

        clearTimeout(timeout);

        timeout = setTimeout(() => {
            func.apply(context, args);
        }, wait);
    };
}

export function fadeOutAudio(audioElement, duration) {
    if (!audioElement) return;

    const initialVolume = audioElement.volume;
    const fadeOutInterval = 50;
    const steps = duration / fadeOutInterval;
    const volumeStep = initialVolume / steps;

    let currentStep = 0;

    const fadeOut = setInterval(() => {
        if (currentStep >= steps) {
            clearInterval(fadeOut);
            audioElement.volume = 0;
            audioElement.pause();
            audioElement.volume = 1;
            return;
        }
        
        audioElement.volume = Math.max(0, audioElement.volume - volumeStep);
        currentStep++;
    }, fadeOutInterval);
}