<template>
  <div class="widget_panel">
    <section
      class="animated faster"
      :class="[`storyscale_ss_modal_section storyscale_ss_modal_active`]"
      style="
        overflow: hidden;
        align-items: center;
        justify-content: center;
        width: 100%;
      "
      :style="`display: ${show ? 'flex' : 'none'}`"
    >
      <div
        class="storyscale_ss_modal_dialog_tour"
        :style="`margin: 0 !important; left: 0; width: ${computedStyles.width};`"
      >
        <div class="storyscale_ss_modal_content" :style="`height: ${computedStyles.height}; padding: 8px;margin: 0px auto;border-radius: ${computedStyles.borderRadius}; overflow: hidden;`">
          <div class="storyscale_ss_modal_tour_close" @click.prevent="closeModal">
            &times; {{ show }}
          </div>
          <section ref="widgetHolder" style="height: 100%"></section>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import TourWidget from "../../tour/widget";
import { getCssUnit } from '../../utils';

export default {
  props: ["token"],
  data() {
    return {
      loading: false,
      show: false,
      animationClass: "",
      iframe: null,
      styles: {
        width: "90%",
        height: "90vh",
        borderRadius: "0px",
      },
      mobileStyles: null,
    };
  },
  created () {
    this.createMediaQueryListener();
  },
  beforeDestroy(){
    this.show = false;
  },
  methods: {
    createWidget(props = {}) {
      this.removeWidget();

      if(props){
        if(props.width) props.launcher_width = props.width;
        if(props.height) props.launcher_height = props.height;
      }
      
      TourWidget.createWidget({
        token: this.token,
        defaultStyles: true,
        launcher: true,
        delivery: "launcher",
        props,
      }).then(({iframe, styles}) => {
        this.iframe = iframe;

        const { value: heightValue, unit: heightUnit } = getCssUnit(styles.launcher_height);
        const height = heightUnit == "%" ? `${heightValue}vh` : styles.launcher_height;
        
        this.styles = {
          width: styles.launcher_width ? styles.launcher_width : this.styles.width,
          height: styles.launcher_height ? height : this.styles.height,
          borderRadius: styles.rounded ? "8px" : "0px",
        }

        if (this.$refs.widgetHolder.firstElementChild) {
          this.$refs.widgetHolder.firstElementChild.replaceChild(this.iframe);
        } else {
          this.$refs.widgetHolder.appendChild(this.iframe);
        }
      }).catch((error) => {
        console.log(error)
      });
    },
    removeWidget(){
       if (this.iframe) this.iframe.remove();
    },
    unloadIframe(){
      if(this.iframe) this.iframe.src = "";
    },
    showModal(props = {}) {
      this.createWidget(props);
      this.show = true;
    },
    closeModal() {
      setTimeout(() => {
        this.show = false;
        this.unloadIframe();
      }, 10);
    },
    createMediaQueryListener(){
        const mediaQuery = window.matchMedia('(max-width: 560px)');

        const handleResponsiveLayoutSwitch = () => mediaQuery.matches ? this.onMobile() : this.onDesktop();

        mediaQuery.addEventListener("change", handleResponsiveLayoutSwitch);

        handleResponsiveLayoutSwitch();
    },
    onDesktop(){
        this.mobileStyles = null;
    },
    onMobile(){
      this.mobileStyles = {
        width: "100%",
        height: "100vh",
      };
    },
  },
  computed: {
    computedStyles(){
      return {
        ...this.styles,
        width: this.mobileStyles ? this.mobileStyles.width : this.styles.width,
        height: this.mobileStyles ? this.mobileStyles.height : this.styles.height,
      }
    }
  },
  watch: {
    show(value){
      value ? document.body.style.overflow = "hidden" 
      : document.body.style.removeProperty("overflow");
    }
  }
};
</script>