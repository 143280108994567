import Vue from 'vue'
import App from './App.vue'
import WebWidget from './web-widget'
import WebLauncher from './web-launcher'
import HubWidget from './hub-widget';
import TourWidget from './tour/widget';
import TourLauncher from './web-launcher/tour'

import 'video.js/dist/video-js.css';

import 'document-register-element/build/document-register-element';
import vueCustomElement from 'vue-custom-element'
Vue.use(vueCustomElement);

window.Vue = Vue;
import axios from 'axios';
import VueAxios from "vue-axios";
const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 100000,
});

window.axios = instance;
Vue.use(VueAxios, instance);

import VueProgressBar from 'vue-progressbar';

Vue.use(VueProgressBar, {
    color: '#4B60EB',
    failedColor: 'red',
    height: '4px',
    thickness: '5px',
});

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
Vue.use(VueToast);

import mixin from './mixin';
import { parseObjectString } from './utils';
mixin(Vue);

Vue.config.productionTip = false;

Vue.customElement('storyscale-widget', App);
Vue.customElement('storyscale-hub', HubWidget);
Vue.customElement('storyscale-web-widget', WebWidget);
Vue.customElement('storyscale-launcher', WebLauncher);
Vue.customElement('storyscale-tour-launcher', TourLauncher);

function domReady() {
    return new Promise(resolve => {
        if (document.readyState == "loading") {
            document.addEventListener("DOMContentLoaded", resolve)
        } else {
            resolve()
        }
    })
}

try {
    if(document.currentScript){
        const url = new URL(document.currentScript.src);
        const origin = url.origin;
        
        const link = document.createElement('link');
        link.rel='stylesheet';
        link.type='text/css';
        link.href=`${origin}/css/app.css`;
    
        document.head.appendChild(link);
    }
} catch (error) {
   console.log("[Storyscale]: ", error.message); 
}

domReady().then(() => {
    //! Refactor this code, way too many repetitions, make common functions for traversal maybe?

    // Widget Html Tag Setup
    function createWidget(element, widgetName, props, experienceType = null) {
        // Filter
        const filterTags = !["STORYSCALE-WEB-WIDGET", "STORYSCALE-WIDGET", "STORYSCALE-HUB"].includes(element.tagName);

        if (!filterTags) return;

        // Create Widget
        let widget = document.createElement(widgetName)

        // Get Props
        Object.keys(props).forEach(propName => {
            props[propName] = element.getAttribute(propName) ?? props[propName]
        })

        // Set Props
        for (let propName in props) {
            props[propName] && widget.setAttribute(propName, props[propName])
        }

        const shouldReplaceElement = (widgetName == "storyscale-web-widget" || experienceType == "badge");

        // if(shouldReplaceElement){
        element.parentElement.insertBefore(widget, element);
        // }else{
        //     document.body.appendChild(widget);
        // }

        element.remove();
    }

    // storyscale-widget elements
    const storyScaleWidgets = document.querySelectorAll("[class*=storyscale-widget]");

    storyScaleWidgets.forEach((element) => {
        let el = element.id.split(',');
        let webtag = el[0].split(':')[1];
        let ptkn = el[1].split(':')[1];
        let dtp = el[2].split(':')[1];
        let type = el[3] ? el[3].split(':')[1] : 'blank';
        let token = el[4] ? el[4].split(':')[1] : '';
        // const props = { type: "blank", token: "", webtag: "", ptkn: "", dtp: "" };
        const props = { type, token, webtag, ptkn, dtp };

        const tokeData = token ? JSON.parse(window.atob(token) ?? "{}") : {};

        const experienceType = tokeData.experience_type ? tokeData.experience_type : null;

        createWidget(element, "storyscale-widget", props, experienceType)
    })

    const storyScaleHub = document.querySelectorAll("[class*=storyscale-hub]");

    storyScaleHub.forEach((element) => {
        let el = element.id.split(',');
        let token = el[0] ? el[0].split(':')[1] : '';
        const props = { token };
        const tokeData = token ? JSON.parse(atob(token) ?? "{}") : {};
        localStorage.setItem(`token`, token);

        const experienceType = tokeData.experience_type ? tokeData.experience_type : null;

        createWidget(element, "storyscale-hub", props, experienceType)
    });

    // storyscale-web-widget elements
    const storyScaleWebWidgets = document.querySelectorAll("[class*=storyscale-web-widget]");

    storyScaleWebWidgets.forEach((element) => {
        let token = element.id ? element.id.split(':')[1] : '';

        const props = { title: "", description: "", type: "", token };

        const tokeData = token ? JSON.parse(atob(token) ?? "{}") : {};

        const experienceType = tokeData.experience_type ? tokeData.experience_type : null;

        createWidget(element, "storyscale-web-widget", props, experienceType)
    })

    // Launcher Button Setup
    function addLauncherWidget(button, token) {
        const filterTags = !["STORYSCALE-WEB-WIDGET"].includes(button.tagName);

        if (token && filterTags) {
            const tokenId = `story-${token}`;
            const doesElementExists = document.getElementById(tokenId);

            if (!doesElementExists) {
                const launcher = document.createElement("storyscale-launcher");
                launcher.setAttribute("token", token);
                launcher.setAttribute("id", tokenId);
                document.body.appendChild(launcher)
            }

            button.addEventListener("click", (event) => {
                event.preventDefault();
                document.getElementById(tokenId).__vue_custom_element__.$children[0].showModal();
            });
        }
    }

    // Via classname
    const storyScaleClassedButtons = document.querySelectorAll("[class*=storyscale-launcher]");

    storyScaleClassedButtons.forEach((button) => {
        const launcherClass = [...button.classList].find(className => className.match(/^storyscale-launcher/));

        const [_, token] = launcherClass.split("storyscale-launcher-");

        addLauncherWidget(button, token);
    });

    // Via custom attribute
    const storyScaleButtons = document.querySelectorAll("[storyscale-launcher]");

    storyScaleButtons.forEach((button) => {
        const token = button.getAttribute("storyscale-launcher");
        addLauncherWidget(button, token);
    })

    // Via links
    const linkFullURL = new URL(process.env.VUE_APP_SHARE_URL);

    const storyScaleLinkButtons = document.querySelectorAll(`[href*='${linkFullURL.toString()}']`);

    storyScaleLinkButtons.forEach((button) => {
        try {
            const url = new URL(button.getAttribute("href"));

            const { host, searchParams } = url;

            const queryParams = {};

            for (let value of searchParams.keys()) {
                queryParams[value] = searchParams.get(value);
            }

            const { launcher, rptn } = queryParams;

            if (launcher !== "true" || clfn === "tour" || rptn === "") return;

            const token = rptn;

            addLauncherWidget(button, token);

        } catch (e) { }
    });

    // Tour
    // ============

    // Widget
    const tourWidgetAttribute = "storyscale-tour";
    const tourWidgetPropsAttribute = "storyscale-props";
    const storyscaleTourWidgets = document.querySelectorAll(`[${tourWidgetAttribute}]`);

    storyscaleTourWidgets.forEach((element) => {
        const token = element.getAttribute(tourWidgetAttribute);

        if (!token) return;

        const propsString = element.getAttribute(tourWidgetPropsAttribute);
        
        let props = {};

        if(propsString) try { props = parseObjectString(propsString) } catch (error) { console.log(error) }

        TourWidget.createWidget({ token, element,  delivery: 'embed', props });
    });

    function addTourLauncherWidget(element, token) {
        if (token) {
            const tokenId = `story-${token}`;
            const doesElementExists = document.getElementById(tokenId);

            if (!doesElementExists) {
                const launcher = document.createElement("storyscale-tour-launcher");
                launcher.setAttribute("token", token);
                launcher.setAttribute("id", tokenId);
                document.body.appendChild(launcher)
            }

            const propsString = element.getAttribute(tourWidgetPropsAttribute);
        
            let props = {};
    
            if(propsString) try { props = parseObjectString(propsString) } catch (error) { console.log(error) }

            element.addEventListener("click", (event) => {
                event.preventDefault();

                document.getElementById(tokenId).__vue_custom_element__.$children[0].showModal(props);
            });
        }
    }

    // Launcher
    const tourLauncherAttribute = "storyscale-tour-launcher";

    // Via custom attribute
    const storyScaleTourLauncherButtons = document.querySelectorAll(`[${tourLauncherAttribute}]`);

    storyScaleTourLauncherButtons.forEach((button) => {
        const token = button.getAttribute(tourLauncherAttribute);
        addTourLauncherWidget(button, token);
    });

    // Via classname
    const storyScaleTourClassedButtons = document.querySelectorAll(`[class*=${tourLauncherAttribute}]`);

    storyScaleTourClassedButtons.forEach((button) => {
        const launcherClass = [...button.classList].find(className => className.startsWith(tourLauncherAttribute));

        if (!launcherClass) return;

        const [, token] = launcherClass.split(`${tourLauncherAttribute}-`);

        addTourLauncherWidget(button, token);
    });

    // Via links
    const tourLinkFullURL = new URL(process.env.VUE_APP_SHARE_URL);

    const storyScaleTourLinkButtons = document.querySelectorAll(`[href*='${tourLinkFullURL.toString()}']`);

    storyScaleTourLinkButtons.forEach((button) => {
        try {
            const url = new URL(button.getAttribute("href"));

            const { searchParams } = url;

            const queryParams = {};

            for (let value of searchParams.keys()) {
                queryParams[value] = searchParams.get(value);
            }

            const { clfn, launcher, rptn } = queryParams;

            if (launcher !== "true" || rptn === "" || clfn !== "tour") return;

            const token = rptn;

            addTourLauncherWidget(button, token);

        } catch (e) { }
    });
});