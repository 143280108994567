import { v4 as uuidv4 } from 'uuid';

const GUID_LOCAL_STORAGE_KEY_NAME = "__stsc:key__";

function generateGUID(){
    return uuidv4().replace(/-/g, "");
}

function generateAndSetGUID(){
    const id = generateGUID();

    localStorage.setItem(GUID_LOCAL_STORAGE_KEY_NAME, id);

    return id
}

export function getGUID(){
    return localStorage.getItem(GUID_LOCAL_STORAGE_KEY_NAME) || generateAndSetGUID();
}